.center {
  text-align: center
}

.progressIndicator p {
  text-align: center;
}

.divFondProgressIndicator {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000001;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.progress-bar {
  height: 4px;
  background-color: rgba(206, 35, 5, 0.204);
  width: 80vw;
  overflow: hidden;
  margin: 0vw 10vw;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgba(206, 35, 5, 0.971);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.4);
  }

  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}