.App {
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.App-header {
  background-color: #fd0400;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
}


.content {
  padding-top:2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin:0 25vw;
  height: 80vh;
}

.footer{
  min-height: 10vh;
  margin:0 10vw;
}

.paragraphMargin5 {
  margin : 0 5rem;
}